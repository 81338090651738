import React from "react";
import styled from "styled-components";
import Text from "../reusables/text";
import Title from "../reusables/title";

export default function About({ aboutUs, aboutUsImageUrl, ourMission }) {
  return (
    <AboutSection>
      <div className="container">
        <Title style={{textAlign: "left", backgroundColor: "#e8bdbf", width: "fit-content", padding: "inherit", marginBottom: "-49px", fontWeight: 'normal', fontSize: '30px' }}>About Us</Title>
        <Content>
        <Rect src={aboutUsImageUrl}></Rect>
        <MainText>{aboutUs || ""}</MainText>
          
        </Content>
      </div>
    </AboutSection>
  );
}

const AboutGrid = styled.div`
  display: grid;
  margin-top: 2vw;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-items: center;
  .about_grid_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 40px;
    width: 250px;
    .about_grid_title {
      font-weight: bold;
      font-size: 18px;
      margin: 8px;
    }
    .about_grid_text {
      margin: 0;
      font-size: 14px;
      font-weight: light;
    }
    .review_icon {
      margin: auto;
      padding: 4px;
    }
  }
`;

const ReviewPlatform = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: ${props => props.background};
`;

const Rect = styled.img`
  margin-right: 20px;
  height: auto;
  width: 50%;
  border-radius: 5%;
  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    margin: 0;
    padding-bottom: 10px;
  }
`;

const MainText = styled(Text)`
  align-self: center;
  margin: 0;
  line-height: 2.0;
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: ${props => props.align || "start"};
  flex-direction: row;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

const AboutSection = styled.section.attrs({
  id: "about",
})`
  padding: 5vw 0;
  padding-top: calc(50px + 6vw);
  color: ${props => props.theme.primaryText};
`;
