import styled from "styled-components";
import {
  FaPlusCircle,
  FaCheckCircle,
  FaEdit,
  FaWindowClose,
} from "react-icons/fa";

export const PlusStyled = styled(FaPlusCircle)`
  cursor: pointer;
`;

export const CheckStyled = styled(FaCheckCircle)`
  cursor: pointer;
  margin: 2px;
  color: ${props => props.theme.primary};
  height: ${props => props.size};
  width: ${props => props.size};
`;

export const EditStyled = styled(FaEdit)`
  margin: 2px;
  cursor: pointer;
  color: ${props => props.theme.primary};
`;

export const DeleteStyled = styled(FaWindowClose)`
  cursor: pointer;
  margin: 2px;
  color: ${props => props.color || props.theme.warningText};
`;

export const ImageContainer = styled.div`
  border-radius: 12px;
  margin: 8px;
  width: 150px;
  height: 150px;
  position: relative;
  border: 1px solid #fff5f5;
`;

export const StyledImage = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 12px;
  object-fit: cover;
`;

export const IconContainer = styled.div`
  display: flex;
  background-color: #fff5f5;
  padding: 4px;
  border-radius: 8px;
  cursor: pointer;
`;

export const StyledLabel = styled.div`
  font-size: 18px;
  padding-top: 8px;
  padding-bottom: 4px;
  font-weight: 500;
  color: ${props => props.color || props.theme.primaryDark};
`;
