import { useEffect, useState } from "react";
import defaultInfo, { defaultAlbum } from "../../helpers/defaultInfo";
import {
  getWebsiteInfo,
  uploadFile,
  setWebsiteInfo as setWebsiteInfoFirebase,
} from "../../helpers/firebase";
import {
  AbsoluteChild,
  Col,
  Row,
  SizedBox,
  Grid,
  Subtitle,
} from "../styles/styled";
import Input, { TextArea } from "../User/reusables/input";
import {
  PlusStyled,
  DeleteStyled,
  CheckStyled,
  EditStyled,
  IconContainer,
  ImageContainer,
  StyledImage,
  StyledLabel,
} from "./styled.js";
import styled from "styled-components";

export function ManageGallery() {
  const [currentAlbum, setCurrentAlbum] = useState();
  const [websiteInfo, setWebsiteInfo] = useState(defaultInfo);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getWebsiteInfo()
      .then(info => {
        setLoading(false);
        setWebsiteInfo({ ...websiteInfo, ...info });
      })
      .catch(e => {
        setLoading(false);
        console.error(e);
      });
  }, []);

  return (
    <SizedBox padding="20px 10px">
      <Row justify="space-between">
        <h5> Gallery</h5>
        <PlusStyled
          onClick={() => {
            if (!currentAlbum) setCurrentAlbum({ ...defaultAlbum });
          }}
        ></PlusStyled>
      </Row>

      {currentAlbum && (
        <AlbumCard
          key={new Date().getTime()}
          incomingAlbum={currentAlbum}
          updateAlbum={updatedAlbum => {
            let index = 0;
            for (; index < websiteInfo.gallery.length; index++) {
              const album = websiteInfo.gallery[index];
              if (album.name === updatedAlbum.name) {
                websiteInfo.gallery[index] = updatedAlbum;
                break;
              }
            }
            if (index >= websiteInfo.gallery.length) {
              websiteInfo.gallery.push(updatedAlbum);
            }
            setWebsiteInfo({
              ...websiteInfo,
              gallery: [...websiteInfo.gallery],
            });
            setCurrentAlbum();
          }}
        ></AlbumCard>
      )}
      {websiteInfo.gallery.map(album => {
        if (album.name === currentAlbum?.name) {
          return <div></div>;
        }
        return (
          <AlbumCard
            key={album.name}
            incomingAlbum={album}
            deleteAlbum={albumToBeDeleted => {
              websiteInfo.gallery = websiteInfo.gallery.filter(
                album => album.name !== albumToBeDeleted.name
              );
              setWebsiteInfo({ ...websiteInfo });
            }}
            startEditing={albumToBeUpdated => {
              setCurrentAlbum(albumToBeUpdated);
            }}
          ></AlbumCard>
        );
      })}
      <Row justify="flex-end">
        {loading ? (
          <div>loading...</div>
        ) : (
          <Row
            style={{ cursor: "pointer" }}
            onClick={async () => {
              if (!window.confirm("Are you sure?")) return;
              setLoading(true);
              await setWebsiteInfoFirebase(websiteInfo);
              setLoading(false);
            }}
          >
            <Subtitle>Apply Changes</Subtitle>
            <CheckStyled size="32px" />
          </Row>
        )}
      </Row>
    </SizedBox>
  );
}

function AlbumCard({ incomingAlbum, updateAlbum, startEditing, deleteAlbum }) {
  const [album, setAlbum] = useState(incomingAlbum);
  const [selectedImage, setFile] = useState(null);
  const [selectedImageSrc, setImageSrc] = useState();

  const [selectedFiles, setFiles] = useState({});
  const [loading, setLoading] = useState();

  async function uploadAndUpdateAlbum() {
    setLoading(true);
    if (selectedImage) {
      album.imageUrl = await uploadFile(
        selectedImage,
        `/gallery/${album.name.replaceAll(" ", "_")}/profile/${
          selectedImage.name
        }`
      );
      setFile(null);
      setImageSrc(null);
    }
    if (Object.entries(selectedFiles).length != 0) {
      for (let i = 0; i < Object.values(selectedFiles).length; i++) {
        const image = Object.values(selectedFiles)[i];
        const imageUrl = await uploadFile(
          image,
          `/gallery/${album.name.replaceAll(" ", "_")}/images/${image.name}`
        );
        if (!album.images) {
          album.images = [];
        }
        album.images.push(imageUrl);
      }
      setFiles({});
    }
    updateAlbum(album);
    setLoading(false);
  }

  function fileSelected(event) {
    if (event.target.files.length !== 0) {
      var file = event.target.files[0];
      setFile(event.target.files[0]);
      var reader = new FileReader();
      reader.onload = function (e) {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  function selectFiles(event) {
    if (event.target.files.length !== 0) {
      [...event.target.files].forEach(async file => {
        var reader = new FileReader();
        reader.onload = async function (e) {
          selectedFiles[e.target.result] = file;
          setFiles({ ...selectedFiles });
        };
        reader.readAsDataURL(file);
      });
    }
  }

  return (
    <AlbumCardStyled editing={updateAlbum}>
      <SizedBox padding="16px 0px">
        <Input
          required
          dark
          disabled={incomingAlbum.name}
          label={updateAlbum ? "Album Name" : ""}
          value={album.name}
          type="text"
          onChange={e =>
            setAlbum({
              ...album,
              name: e.target.value,
            })
          }
        />
      </SizedBox>
      <SizedBox padding="16px 0px">
        <TextArea
          required
          dark
          rows={2}
          type="text"
          disabled={!updateAlbum}
          label={updateAlbum ? "Album Description" : ""}
          value={album.description}
          onChange={e =>
            setAlbum({
              ...album,
              description: e.target.value,
            })
          }
        />
      </SizedBox>

      <StyledLabel>Album Images</StyledLabel>
      {album.images?.length != 0 && (
        <Col>
          <Grid>
            {album.images?.map((imageSrc, index) => {
              return (
                <ImageContainer>
                  <StyledImage src={imageSrc} />
                  {updateAlbum && (
                    <AbsoluteChild top="0px" right="0px">
                      <IconContainer
                        onClick={() => {
                          album.images = album.images.filter(
                            src => src != imageSrc
                          );
                          setFiles({ ...selectedFiles });
                        }}
                      >
                        <DeleteStyled></DeleteStyled>
                      </IconContainer>
                    </AbsoluteChild>
                  )}
                </ImageContainer>
              );
            })}
          </Grid>
        </Col>
      )}
      {updateAlbum && (
        <SizedBox>
          <input
            type="file"
            name="imageUrl"
            accept="image/"
            id="file_picker"
            multiple
            onChange={selectFiles}
          />
        </SizedBox>
      )}
      {selectedFiles.length != 0 && (
        <Col>
          <Row>
            {Object.keys(selectedFiles).map((imageSrc, index) => {
              return (
                <ImageContainer>
                  <StyledImage src={imageSrc} />
                  {updateAlbum && (
                    <AbsoluteChild top="0px" right="0px">
                      <IconContainer
                        onClick={() => {
                          delete selectedFiles[imageSrc];
                          setFiles({ ...selectedFiles });
                        }}
                      >
                        <DeleteStyled></DeleteStyled>
                      </IconContainer>
                    </AbsoluteChild>
                  )}
                </ImageContainer>
              );
            })}
          </Row>
        </Col>
      )}

      <Row justify="flex-end">
        {updateAlbum ? (
          loading ? (
            <div>Uploading files</div>
          ) : (
            <CheckStyled onClick={uploadAndUpdateAlbum} />
          )
        ) : (
          <AbsoluteChild top="0px">
            <Row>
              <EditStyled
                onClick={() => {
                  startEditing(album);
                }}
              ></EditStyled>
              <DeleteStyled
                onClick={() => {
                  deleteAlbum(album);
                }}
              ></DeleteStyled>
            </Row>
          </AbsoluteChild>
        )}
      </Row>
    </AlbumCardStyled>
  );
}

const AlbumCardStyled = styled.div`
  padding: 12px 8px;
  margin-bottom: 16px;
  border: 1px solid;
  max-width: ${props => (props.editing ? "100%" : "440px")};
  border-width: ${props => (props.editing ? "2px" : "1px")};
  border-color: ${props => (props.editing ? "green" : props.theme.primaryDark)};
  box-shadow: ${props => (props.editing ? "0 2px 4px black" : "none")};
  border-radius: 8px;
  position: relative;
`;
