import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";

const theme = {
  secondary: "#00fcfe",
  primary: "hsl(354, 70%, 54%)",
  primaryLight: "hsl(354, 70%, 75%)",
  primaryExtraLight: "hsl(354, 71%, 85%)",
  primaryDark: "hsla(355, 69%, 15%,1)",
  primaryText: "hsla(357, 69%, 5%,1)",
  warningLight: "hsla(0, 95%, 75%,1)",
  warningExtraLight: "hsla(0, 97%, 90%, 1)",
  warningDark: "hsl(240, 100%, 10%)",
  warningText: "hsl(240, 100%, 10%)",
  positiveColor: "hsl(150, 50%, 40%)",
  negativeColor: "rgb(255,0,0)",
};

const GlobalStyle = createGlobalStyle`
  section:nth-child(2n-1) {
    background-color: hsl(30, 31%, 95%);
  }
  section:nth-child(2n) {
    background: white;
  }
  .container {
    color: ${props => props.theme.primaryText};
    width: 100%;
    padding: 25px 15px;
    margin-right: auto;

    margin-left: auto;
    @media (min-width: 576px) {
      max-width: 540px;
    }
    @media (min-width: 768px) {
      max-width: 720px;
    }
    @media (min-width: 992px) {
      max-width: 960px;
    }
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
    @media (min-width: 1800px) {
      max-width: 1600px
    }
  }
`;

export default ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};
