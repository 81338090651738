import { useEffect, useState } from "react";
import defaultInfo, { defaultTraining } from "../../helpers/defaultInfo";
import {
  getWebsiteInfo,
  uploadFile,
  setWebsiteInfo as setWebsiteInfoFirebase,
} from "../../helpers/firebase";
import {
  AbsoluteChild,
  Col,
  Row,
  SizedBox,
  Grid,
  Subtitle,
} from "../styles/styled";
import Input, { TextArea } from "../User/reusables/input";
import {
  PlusStyled,
  DeleteStyled,
  CheckStyled,
  EditStyled,
  IconContainer,
  ImageContainer,
  StyledImage,
  StyledLabel,
} from "./styled.js";
import styled from "styled-components";

export function ManageTrainings() {
  const [currentTraining, setCurrentTraining] = useState();
  const [websiteInfo, setWebsiteInfo] = useState(defaultInfo);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getWebsiteInfo()
      .then(info => {
        setLoading(false);
        console.log(info);
        setWebsiteInfo({ ...websiteInfo, ...info });
      })
      .catch(e => {
        setLoading(false);
        console.error(e);
      });
  }, []);

  return (
    <SizedBox padding="20px 10px">
      <Row justify="space-between">
        <h5> Trainings</h5>
        <PlusStyled
          onClick={() => {
            if (!currentTraining) setCurrentTraining(defaultTraining);
          }}
        ></PlusStyled>
      </Row>

      {currentTraining && (
        <TrainingCard
          key={new Date().getTime()}
          incomingTraining={currentTraining}
          updateTraining={updatedTraining => {
            let index = 0;
            for (; index < websiteInfo.trainings.length; index++) {
              const training = websiteInfo.trainings[index];
              if (training.name === updatedTraining.name) {
                websiteInfo.trainings[index] = updatedTraining;
                break;
              }
            }
            if (index >= websiteInfo.trainings.length) {
              websiteInfo.trainings.push(updatedTraining);
            }
            setWebsiteInfo({
              ...websiteInfo,
              trainings: [...websiteInfo.trainings],
            });
            setCurrentTraining();
          }}
        ></TrainingCard>
      )}
      {websiteInfo.trainings.map(training => {
        if (training.name === currentTraining?.name) {
          return <div></div>;
        }
        return (
          <TrainingCard
            key={training.name}
            incomingTraining={training}
            deleteTraining={trainingToBeDeleted => {
              websiteInfo.trainings = websiteInfo.trainings.filter(
                training => training.name !== trainingToBeDeleted.name
              );
              setWebsiteInfo({ ...websiteInfo });
            }}
            startEditing={trainingToBeUpdated => {
              setCurrentTraining(trainingToBeUpdated);
            }}
          ></TrainingCard>
        );
      })}
      <Row justify="flex-end">
        {loading ? (
          <div>loading...</div>
        ) : (
          <Row
            style={{ cursor: "pointer" }}
            onClick={async () => {
              if (!window.confirm("Are you sure?")) return;
              setLoading(true);
              console.log(websiteInfo);
              await setWebsiteInfoFirebase(websiteInfo);
              setLoading(false);
            }}
          >
            <Subtitle>Apply Changes</Subtitle>
            <CheckStyled size="32px" />
          </Row>
        )}
      </Row>
    </SizedBox>
  );
}

function TrainingCard({
  incomingTraining,
  updateTraining,
  startEditing,
  deleteTraining,
}) {
  const [training, setTraining] = useState(incomingTraining);
  const [selectedImage, setFile] = useState(null);
  const [selectedImageSrc, setImageSrc] = useState();

  const [selectedFiles, setFiles] = useState({});
  const [loading, setLoading] = useState();

  async function uploadAndUpdateTraining() {
    setLoading(true);
    if (selectedImage) {
      training.imageUrl = await uploadFile(
        selectedImage,
        `/trainings/${training.name.replaceAll(" ", "_")}/profile/${
          selectedImage.name
        }`
      );
      setFile(null);
    }
    if (Object.entries(selectedFiles).length != 0) {
      for (let i = 0; i < Object.values(selectedFiles).length; i++) {
        const image = Object.values(selectedFiles)[i];
        const imageUrl = await uploadFile(
          image,
          `/trainings/${training.name.replaceAll(" ", "_")}/images/${
            image.name
        }`
        );
        if (!training.images) {
          training.images = [];
        }
        training.images.push(imageUrl);
      }
    }
    updateTraining(training);
    setLoading(false);
  }

  function fileSelected(event) {
    if (event.target.files.length !== 0) {
      var file = event.target.files[0];
      setFile(event.target.files[0]);
      var reader = new FileReader();
      reader.onload = function (e) {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  function selectFiles(event) {
    if (event.target.files.length !== 0) {
      [...event.target.files].forEach(async file => {
        var reader = new FileReader();
        reader.onload = async function (e) {
          selectedFiles[e.target.result] = file;
          setFiles({ ...selectedFiles });
        };
        reader.readAsDataURL(file);
      });
    }
  }

  return (
    <TrainingCardStyled editing={updateTraining}>
      <SizedBox padding="16px 0px">
        <Input
          required
          dark
          disabled={incomingTraining.name}
          label={updateTraining ? "Training Name" : ""}
          value={training.name}
          type="text"
          onChange={e =>
            setTraining({
              ...training,
              name: e.target.value,
            })
          }
        />
      </SizedBox>
      <SizedBox padding="16px 0px">
        <TextArea
          required
          dark
          rows={2}
          type="text"
          disabled={!updateTraining}
          label={updateTraining ? "Training Description" : ""}
          value={training.description}
          onChange={e =>
            setTraining({
              ...training,
              description: e.target.value,
            })
          }
        />
      </SizedBox>

      {updateTraining && (
        <>
          <SizedBox padding="16px 0px">
            <TextArea
              required
              dark
              rows={6}
              type="text"
              disabled={!updateTraining}
              label={updateTraining ? "Training Definition" : ""}
              value={training.definition}
              onChange={e =>
                setTraining({
                  ...training,
                  definition: e.target.value,
                })
              }
            />
          </SizedBox>
          <SizedBox padding="16px 0px">
            <TextArea
              required
              dark
              rows={2}
              type="text"
              disabled={!updateTraining}
              label={
                updateTraining ? "What will I learn from the training ?" : ""
              }
              value={training.courseContent}
              onChange={e =>
                setTraining({
                  ...training,
                  courseContent: e.target.value,
                })
              }
            />
          </SizedBox>
          <SizedBox padding="16px 0px">
            <TextArea
              required
              dark
              rows={6}
              type="text"
              disabled={!updateTraining}
              label={
                updateTraining ? "What will be included in the training?" : ""
              }
              value={training.topicsCovered}
              onChange={e =>
                setTraining({
                  ...training,
                  topicsCovered: e.target.value,
                })
              }
            />
          </SizedBox>
          <SizedBox padding="16px 0px">
            <TextArea
              required
              dark
              rows={6}
              type="text"
              disabled={!updateTraining}
              label={updateTraining ? "Schedule of the class " : ""}
              value={training.classSchedule}
              onChange={e =>
                setTraining({
                  ...training,
                  classSchedule: e.target.value,
                })
              }
            />
          </SizedBox>
          <SizedBox padding="16px 0px">
            <TextArea
              required
              dark
              rows={6}
              type="text"
              disabled={!updateTraining}
              label={updateTraining ? "PRE-REQUISITES" : ""}
              value={training.prerequisites}
              onChange={e =>
                setTraining({
                  ...training,
                  prerequisites: e.target.value,
                })
              }
            />
          </SizedBox>
          <SizedBox padding="16px 0px">
            <TextArea
              required
              dark
              rows={6}
              type="text"
              disabled={!updateTraining}
              label={updateTraining ? "How to sign up for the class" : ""}
              value={training.howToSignup}
              onChange={e =>
                setTraining({
                  ...training,
                  howToSignup: e.target.value,
                })
              }
            />
          </SizedBox>
          <SizedBox padding="16px 0px">
            <TextArea
              required
              dark
              rows={2}
              type="text"
              disabled={!updateTraining}
              label={updateTraining ? "Disclaimer for trainees" : ""}
              value={training.disclaimer}
              onChange={e =>
                setTraining({
                  ...training,
                  disclaimer: e.target.value,
                })
              }
            />
          </SizedBox>
          <SizedBox padding="16px 0px">
            <TextArea
              required
              dark
              rows={6}
              type="text"
              disabled={!updateTraining}
              label={updateTraining ? "Upcoming Classes" : ""}
              value={training.upcomingClasses}
              onChange={e =>
                setTraining({
                  ...training,
                  upcomingClasses: e.target.value,
                })
              }
            />
          </SizedBox>
          <SizedBox padding="16px 0px">
            <TextArea
              required
              dark
              rows={2}
              type="text"
              disabled={!updateTraining}
              label={updateTraining ? "Registration link" : ""}
              value={training.registrationLink}
              onChange={e =>
                setTraining({
                  ...training,
                  registrationLink: e.target.value,
                })
              }
            />
          </SizedBox>
          <SizedBox padding="16px 0px">
            <TextArea
              required
              dark
              rows={2}
              type="text"
              disabled={!updateTraining}
              label={
                updateTraining
                  ? "Custom HTML(only if a new information is required)"
                  : ""
              }
              value={training.customHtml}
              onChange={e =>
                setTraining({
                  ...training,
                  customHtml: e.target.value,
                })
              }
            />
          </SizedBox>
        </>
      )}
      <StyledLabel>Training Default image</StyledLabel>
      {(selectedImageSrc || training.imageUrl) && (
        <Col>
          <ImageContainer>
            <StyledImage
              src={selectedImageSrc ? selectedImageSrc : training.imageUrl}
            />
            {updateTraining && (
              <AbsoluteChild top="0px" right="0px">
                <IconContainer
                  onClick={() => {
                    if (selectedImageSrc) {
                      setImageSrc(null);
                      setFile(null);
                    } else {
                      setTraining({
                        ...training,
                        imageUrl: null,
                      });
                    }
                  }}
                >
                  <DeleteStyled></DeleteStyled>
                </IconContainer>
              </AbsoluteChild>
            )}
          </ImageContainer>
        </Col>
      )}
      {updateTraining && (
        <SizedBox>
          <input
            type="file"
            name="imageUrl"
            accept="image/"
            id="file_picker"
            onChange={fileSelected}
          />
        </SizedBox>
      )}

      <StyledLabel>Training Images</StyledLabel>
      {training.images?.length != 0 && (
        <Col>
          <Grid>
            {training.images?.map((imageSrc, index) => {
              return (
                <ImageContainer>
                  <StyledImage src={imageSrc} />
                  {updateTraining && (
                    <AbsoluteChild top="0px" right="0px">
                      <IconContainer
                        onClick={() => {
                          training.images = training.images.filter(
                            src => src != imageSrc
                          );
                          setFiles({ ...selectedFiles });
                        }}
                      >
                        <DeleteStyled></DeleteStyled>
                      </IconContainer>
                    </AbsoluteChild>
                  )}
                </ImageContainer>
              );
            })}
          </Grid>
        </Col>
      )}
      {updateTraining && (
        <SizedBox>
          <input
            type="file"
            name="imageUrl"
            accept="image/"
            id="file_picker"
            multiple
            onChange={selectFiles}
          />
        </SizedBox>
      )}
      {selectedFiles.length != 0 && (
        <Col>
          <Row>
            {Object.keys(selectedFiles).map((imageSrc, index) => {
              return (
                <ImageContainer>
                  <StyledImage src={imageSrc} />
                  {updateTraining && (
                    <AbsoluteChild top="0px" right="0px">
                      <IconContainer
                        onClick={() => {
                          delete selectedFiles[imageSrc];
                          setFiles({ ...selectedFiles });
                        }}
                      >
                        <DeleteStyled></DeleteStyled>
                      </IconContainer>
                    </AbsoluteChild>
                  )}
                </ImageContainer>
              );
            })}
          </Row>
        </Col>
      )}

      <Row justify="flex-end">
        {updateTraining ? (
          loading ? (
            <div>Uploading files</div>
          ) : (
            <CheckStyled onClick={uploadAndUpdateTraining} />
          )
        ) : (
          <AbsoluteChild top="0px">
            <Row>
              <EditStyled
                onClick={() => {
                  startEditing(training);
                }}
              ></EditStyled>
              <DeleteStyled
                onClick={() => {
                  deleteTraining(training);
                }}
              ></DeleteStyled>
            </Row>
          </AbsoluteChild>
        )}
      </Row>
    </TrainingCardStyled>
  );
}

const TrainingCardStyled = styled.div`
  padding: 12px 8px;
  margin-bottom: 16px;
  border: 1px solid;
  max-width: ${props => (props.editing ? "100%" : "440px")};
  border-width: ${props => (props.editing ? "2px" : "1px")};
  border-color: ${props => (props.editing ? "green" : props.theme.primaryDark)};
  box-shadow: ${props => (props.editing ? "0 2px 4px black" : "none")};
  border-radius: 8px;
  position: relative;
`;
