import React from "react";
import styled from "styled-components";

import Title from "../../reusables/title";
import { Row, Col } from "../../../styles/styled";
import { useHistory } from "react-router-dom";

export default function Trainings({ trainings }) {
  return (
    <TrainingsSection>
      <div style={{position: 'relative'}}className="container">
        <Circle size="25px" ></Circle>
        <Title style={{textAlign: "left", backgroundColor: "#e8bdbf", width: "fit-content", padding:"2px", paddingLeft: '25px', marginBottom: "-49px", letterSpacing: '5px', lineHeight:"2", fontSize: '20px', fontWeight: 'normal'}}>Training we offer</Title>
        {trainings.map(training => (
          <TrainingCard key={training.name} training={training}></TrainingCard>
        ))}
      </div>
    </TrainingsSection>
  );
}

function TrainingCard({ training }) {
  const history = useHistory();
  return (
    <>
      <TrainingStyled
        onClick={() => {
          history.push(`/trainings/${training.name}`);
        }}
      >
        <NameAndImage>
          <div>
          <Row align="center">
          <TrainingName style={{textAlign: "left", color: "rgb(202 155 157)", width: "fit-content", padding: "10px", lineHeight:"2" }}>{training.name}</TrainingName>
        </Row>
        <TrainingDetail style={{minWidth: '50%', letterSpacing: 'normal', display: 'inline'}}>{training.description}</TrainingDetail>
        <span style={{textTransform: 'capitalize', color: 'blue', }}>Learn more</span>
          </div>
          <ImageContainer style={{minWidth: '50%'}}>
            <TrainingImage src={training.imageUrl}></TrainingImage>
          </ImageContainer>
        </NameAndImage>
        
      </TrainingStyled>
    </>
  );
}

const TrainingsSection = styled.section.attrs({
  id: "trainings",
})`
  padding: 5vw 0;
  padding-top: calc(50px + 6vw);
  .row {
    margin-top: 50px;
    text-align: center;
  }
  .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      object-fit: cover;
      width: 100%;
      max-width: 100vw;
      height: auto;
      max-height: 330px;
      border-radius: 8px;
    }
    a {
      color: ${props => props.theme.primaryText};
      margin-top: 18px;
      font-weight: bold;
    }
  }
`;

const TrainingStyled = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-top: 8vh;
  margin-bottom: 8vh;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
`;

const NameAndImage = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  flex: 7;
`;

const TrainingImage = styled.img`
  display: flex;
  flex: 1;
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 450px;
  text-transform: uppercase;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 3;
  padding-left: 24px;
`;

const TrainingName = styled.div`
  display: flex;
  flex: 1;
  font-weight: 500;
  width: min-content;
  padding: 24px 12px;
  font-size: 36px;
  letter-spacing: 0.1em;
  // writing-mode: vertical-rl;
  // text-orientation: mixed;
  // transform: rotate(-180deg);
  @media (max-width: 1024px) {
    transform: none;
    writing-mode: horizontal-tb;
    letter-spacing: 0.05em;
    text-orientation: mixed;
  }
`;

const TrainingDetail = styled.div`
  display: flex;
  flex: 3;
  padding: 8px;
  margin-right: 12px;
`;

const Circle = styled.div`
  margin: 8px;
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 100%;
  border: 2px solid black;
  background-color: white;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 10px;

`;

const Line = styled.div`
  flex: 1;
  border: 0.5px solid ${props => props.theme.primaryDark};
  height: 100%;
  width: 0px;
`;
