import React from "react"
import styled, { css } from "styled-components"

export default function Burger({ onChange, open }) {
  return (
    <BurgerButtonContainer open={open} type="button" onClick={onChange}>
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </BurgerButtonContainer>
  )
}

const BurgerButtonContainer = styled.button`
  /* padding: 15px 15px; */
  display: inline-flex;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none;

  .hamburger-box {
    width: 28px;
    height: 20px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 28px;
    height: 4px;
    background-color: ${props => props.theme.primary};
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner {
    top: 2px;
  }
  .hamburger-inner::before {
    top: 8px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s;
  }
  .hamburger-inner::after {
    top: 16px;
  }

  ${props =>
    props.open
      ? css`
          .hamburger-inner {
            transform: translate3d(0, 8px, 0) rotate(45deg);
          }
          .hamburger-inner::before {
            transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
            opacity: 0;
          }
          .hamburger-inner::after {
            transform: translate3d(0, -16px, 0) rotate(-90deg);
          }
        `
      : null}
`
