import styled from "styled-components";
import React from "react";

function ButtonBase({ children, className, style, disabled, onClick }) {
  return (
    <button
      className={className}
      style={style}
      disabled={disabled}
      onClick={onClick}
    >
      <span>{children}</span>
    </button>
  );
}

export const Button = styled(ButtonBase)`
  background-color: ${props => props.theme.secondary};
  padding: ${props => props.padding || "14px 24px"};
  /* width: 100%; */
  border-radius: 24px;
  color: #000;
  border-color: #fff;
  border-style: none;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  min-width: 225px;
  max-width: 225px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
  letter-spacing: normal;
  /* overflow: hidden; */
  position: relative;
  transform: translate3d(0, 0, 0);
  &:hover {
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.7));
  }
  &:disabled {
    cursor: not-allowed;
    background: #ccc;
    &:hover {
      filter: none;
    }
  }
`;

export const OutlineButton = styled(Button)`
  color: ${props => props.theme.primary};
  box-shadow: inset 0 0 0 2px white;
  background-color: transparent;
  min-width: 125px;
  &:hover {
    filter: none;
    border-color: ${props => props.theme.primary};
    border: 1px solid;
  }
`;

export const PrimaryButton = styled(Button)`
  color: white;

  background: ${props => props.theme.primaryDark};
`;
