import React, { useState } from "react";
import Styled from "styled-components";
import { loginWithEmailPassword } from "../../helpers/firebase";
import { Row, SizedBox } from "../styles/styled";
import Input, { TextArea } from "../User/reusables/input";
import { PrimaryButton } from "../User/reusables/buttons";

// functional component
const Login = props => {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(true);
  const [email, setEmail] = useState({
    value: "",
    error: null,
  });
  const [password, setPassword] = useState({ value: "", error: null });
  const [formError, setFormError] = useState(false);

  function showFormError(error) {
    setFormError(error);
    setTimeout(() => {
      setFormError(false);
    }, 6000);
  }

  const attemptLogin = e => {
    e.preventDefault();
    if (ValidateEmail(email.value)) {
      setValidated(false);
      setEmail({
        ...email,
        error: ValidateEmail(email.value),
      });
      return;
    }
    if (password.value.length === 0) {
      setValidated(false);
      setPassword({
        ...password,
        error: "Don't leave this field empty",
      });
      return;
    }

    setLoading(true);
    setEmail({ ...email, error: null });
    setPassword({ ...password, error: null });

    loginWithEmailPassword(email.value, password.value)
      .then(() => setLoading(false))
      .catch(err => {
        showFormError(err.message);
        setLoading(false);
      });
  };

  const emailInput = event => {
    setEmail({
      ...email,
      value: event.target.value,
      error: !validated ? ValidateEmail(event.target.value) : false,
    });
  };

  const passwordInput = event => {
    setPassword({
      ...password,
      value: event.target.value,
    });
  };

  // returns the content of Login page
  return (
    <>
      <Overlay>
        <StyledCard>
          <Row justify="center" align="flex-end">
            <LogoText {...props}>Riza Styles </LogoText>
            <SubText> Admin</SubText>
          </Row>
          <LoginForm {...props}>
            <StyledForm>
              <StyledLabel>Email</StyledLabel>
              <Input
                dark
                error={email.error}
                helperText={email.error}
                required
                id="email"
                type="email"
                name="email"
                value={email.value}
                onChange={emailInput}
              />
              <SizedBox height={18}></SizedBox>
              <StyledLabel>Password</StyledLabel>
              <Input
                dark
                helperText={password.error}
                error={password.error}
                required
                id="password"
                type="password"
                name="password"
                value={password.value}
                onKeyPress={e => {
                  let keyCode;
                  if (window.event) {
                    keyCode = window.event.key.toLowerCase();
                  } else if (e.which) {
                    keyCode = e.which.toString();
                  }
                  if (keyCode.toLowerCase() === "enter") {
                    attemptLogin();
                  }
                }}
                onChange={passwordInput}
              />

              <SizedBox height={32}></SizedBox>

              {formError && <Error> {formError}</Error>}
              <PrimaryButton
                variant="contained"
                justify="center"
                color="primary"
                onClick={e => (!loading ? attemptLogin(e) : null)}
              >
                {loading && (
                  <div size={20} color="white">
                    ...
                  </div>
                )}
                {!loading && "LOGIN"}
              </PrimaryButton>
            </StyledForm>
          </LoginForm>
          {/* <Link href="#" variant="body1">
            Forgot password?
          </Link> */}
        </StyledCard>
      </Overlay>
    </>
  );
};

const ValidateEmail = email => {
  if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return false;
  } else if (email.length === 0) {
    return "Please dont leave this field empty";
  } else {
    return "Please enter a valid email address";
  }
};

export default Login;
const Overlay = Styled.div`
position:fixed;
top:0;
left:0;
height:100vh;
width:100vw;
justify-content:center;
display:flex;
`;

const StyledCard = Styled.div`
border-width: 1.5px;
border-color:${props => props.theme.warningExtraLight};
border-style:solid;
margin:auto;
width:400px;
padding:10px;
padding:20px 0px;
&&{
border-radius:22px;
}
display:flex;
flex-direction:column;

@media (max-width: 450px) {
    width: 100%;
    &&{
    border-radius:0px;
    }
  }
`;

const LogoText = Styled.div`
    align-self:center;
    color:${props => props.theme.primary};
    font-size:36px;
`;

const LoginForm = Styled.div`
  color:${props => props.theme.primary}
  display: flex;
  flex: 1;
  justify-content: center;
  width:290px;
  margin:auto;
  flex-direction: column;
  @media (max-width:450px){
    width:80vw;
  }
`;

const SubText = Styled.div`
  font-size:12px;
  padding-top: 8px;
  padding-bottom: 4px;
  font-weight:500;  
  color: ${props => props.theme.primaryDark};
`;

const StyledLabel = Styled.div`
  font-size:18px;
  padding-top: 8px;
  padding-bottom: 4px;
  font-weight:500;  
  color: ${props => props.theme.primaryDark};
`;
const Error = Styled.div`
  font-size:14px;
  color: ${props => props.theme.warningLight};
`;

export const StyledForm = Styled.form`
  display: flex;
  flex-direction: column;
  height: 50%;
  padding:15px 0px;
  justify-content: space-evenly;
  @media (max-width:450px){
    width:100%;
  }
`;

export const RowCenterChild = Styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
`;
