import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { getWebsiteInfo } from "../../../../helpers/firebase";
import { CenterScreen, Col, Row, Subtitle } from "../../../styles/styled";
import logo from "../../images/logo.png";
import Title from "../../reusables/title";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Button } from "../../reusables/buttons";

export function TrainingDetail({ websiteCache }) {
  const history = useHistory();
  const [websiteInfo, setWebsiteInfo] = useState(websiteCache);
  const [loading, setLoading] = useState(false);
  const [trainingDetail, setTraining] = useState();
  const [trainingName, setName] = useState();

  useEffect(() => {
    const pathnameSplit = history.location.pathname.split("/");
    setName(pathnameSplit[pathnameSplit.length - 1]);
  }, [history.location.pathname]);

  useEffect(() => {
    if (!websiteCache) {
      setLoading(true);
      getWebsiteInfo().then(info => {
        setWebsiteInfo(info);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (trainingName && websiteInfo) {
      console.log(trainingName, websiteInfo);
      const training = websiteInfo.trainings.find(
        training => training.name === trainingName
      );

      if (training) {
        setTraining(training);
      } else {
        goBack();
      }
    }
  }, [websiteInfo, trainingName]);

  function goBack() {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.replace("/");
    }
  }

  function renderContentTitle(formattedContent) {
    if (formattedContent) {
      const heading = formattedContent.split("->")[0];
      let title = "";
      let subtitle = "";
      if (heading) {
        const headingSplit = heading.split("=>");

        title = headingSplit[0];
        if (headingSplit.length > 1) {
          subtitle = headingSplit[1];
        }
      }

      console.log(title, subtitle);
      return (
        <Col>
          <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
          <p dangerouslySetInnerHTML={{ __html: subtitle }}></p>
        </Col>
      );
    }
  }

  function renderAnScheduledEventInfo(rawEventInfo) {
    if (!rawEventInfo) {
      return;
    }
    const eventSections = rawEventInfo.split("::");
    const time = eventSections[0];
    let scheduledTask;
    if (eventSections.length > 1) {
      scheduledTask = eventSections[1];
    }
    return (
      <EventContainer>
        <ScheduledTime
          dangerouslySetInnerHTML={{
            __html: time,
          }}
        ></ScheduledTime>
        <EventDetail
          dangerouslySetInnerHTML={{
            __html: scheduledTask,
          }}
        ></EventDetail>
      </EventContainer>
    );
  }

  function renderScheduleSection(fullSchedule) {
    return (
      <>
        {fullSchedule.split("~>").map((content, index) => {
          return (
            <>
              {index === 0 ? <h3> {content}</h3> : renderContentTitle(content)}
              <ul>
                {content?.split("->").map((point, index) => {
                  // first point is the description
                  if (index == 0) {
                    return <span></span>;
                  }
                  return renderAnScheduledEventInfo(point);
                })}
              </ul>
            </>
          );
        })}
      </>
    );
  }

  function renderSection(content) {
    return (
      <>
        {renderContentTitle(content)}
        <ul>
          {content?.split("->").map((point, index) => {
            // first point is the description
            if (index == 0) {
              return <span></span>;
            }
            return (
              <StyledLI dangerouslySetInnerHTML={{ __html: point }}></StyledLI>
            );
          })}
        </ul>
      </>
    );
  }

  function renderBookingPanelContent(content) {
    return (
      <ul>
        {content?.split("->").map((point, index) => {
          // first point is the description
          if (index == 0) {
            return <div></div>;
          }
          return (
            <StyledLI dangerouslySetInnerHTML={{ __html: point }}></StyledLI>
          );
        })}
      </ul>
    );
  }

  function renderAnUpcomingClass(upcomingClassRaw) {
    if (!upcomingClassRaw) {
      return;
    }
    const classInfoSplit = upcomingClassRaw.split("::");
    const time = classInfoSplit[0];
    let description;
    if (classInfoSplit.length > 1) {
      description = classInfoSplit[1];
    }
    let status = "booked";
    if (classInfoSplit.length > 2) {
      status = classInfoSplit[2];
    }
    return (
      <UpcomingClassContainer>
        <ClassTime
          dangerouslySetInnerHTML={{
            __html: time,
          }}
        ></ClassTime>
        <ClassDetails
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></ClassDetails>
        <ClassStatus
          available={!status.toLowerCase().includes("sold")}
          dangerouslySetInnerHTML={{
            __html: status,
          }}
        ></ClassStatus>
      </UpcomingClassContainer>
    );
  }

  function renderUpcomingClassesSection(upcomingClasses) {
    return (
      <>
        {upcomingClasses.split("~>").map(content => {
          return (
            <>
              {renderContentTitle(content)}
              <ul>
                {content?.split("->").map((point, index) => {
                  // first point is the description
                  if (index == 0) {
                    return <span></span>;
                  }
                  return renderAnUpcomingClass(point);
                })}
              </ul>
            </>
          );
        })}
      </>
    );
  }

  if (loading)
    return (
      <CenterScreen>
        <picture>
          <source srcSet={logo} type="image/png" className="logo" />
          <img src={logo} className="logo" alt="Riza Styles Logo" />
        </picture>
      </CenterScreen>
    );
  if (!trainingDetail) return <div>loading</div>;
  return (
    <DetailPage>
      <Col className="container" align="start">
        <Title>{trainingName}</Title>
        <TrainingHeader>
          <TrainingImage src={trainingDetail.images? trainingDetail.images[0] : trainingDetail.imageUrl}></TrainingImage>
          <p
            dangerouslySetInnerHTML={{ __html: trainingDetail?.description }}
          ></p>
        </TrainingHeader>
        {renderSection(trainingDetail.definition)}
        {renderSection(trainingDetail.courseContent)}
        {renderSection(trainingDetail.topicsCovered)}
        {renderScheduleSection(trainingDetail.classSchedule)}
        {renderSection(trainingDetail.prerequisites)}

        {renderSection(trainingDetail.howToSignup)}

        {trainingDetail.customHtml && (
          <div
            dangerouslySetInnerHTML={{ __html: trainingDetail.customHtml }}
          ></div>
        )}

        <br></br>

        <Disclaimer
          dangerouslySetInnerHTML={{
            __html: trainingDetail.disclaimer,
          }}
        ></Disclaimer>
        <Tabs>
          <TabList>
            {trainingDetail.preBookingInfo && <Tab>Pre-booking Info</Tab>}
            {trainingDetail.bookingPolicy && <Tab>Booking Policy</Tab>}
            {trainingDetail.beforeAppointment && <Tab>Before Appointment</Tab>}
            {trainingDetail.afterAppointment && <Tab>After Appointment</Tab>}
          </TabList>
          {trainingDetail.preBookingInfo && (
            <TabPanel>
              {renderBookingPanelContent(trainingDetail.preBookingInfo)}
            </TabPanel>
          )}
          {trainingDetail.bookingPolicy && (
            <TabPanel>
              {renderBookingPanelContent(trainingDetail.bookingPolicy)}
            </TabPanel>
          )}{" "}
          {trainingDetail.beforeAppointment && (
            <TabPanel>
              {renderBookingPanelContent(trainingDetail.beforeAppointment)}
            </TabPanel>
          )}{" "}
          {trainingDetail.afterAppointment && (
            <TabPanel>
              {renderBookingPanelContent(trainingDetail.afterAppointment)}
            </TabPanel>
          )}
        </Tabs>
        <br />

        {renderUpcomingClassesSection(trainingDetail.upcomingClasses)}

        {trainingDetail.upcomingClasses && trainingDetail.registrationLink && (
             <ContactUs>
             <Row justify="space-between" align="center">
               <>Register Online for {trainingDetail.name} classes.</>
                <a target="_blank" href={trainingDetail.registrationLink}>
                <Button>
                  Register
                  </Button>
                  </a>
             </Row>
           </ContactUs>
        )}
     <p style={{fontSize:"14px", backgroundColor:"pink", color: "black", padding: "5px", borderRadius: "10px", marginTop: "5px"}}> Individual trainings are also available. Please contact us for more detail</p>
      </Col>
    </DetailPage>
  );
}

const DetailPage = styled.div`
  padding-top: 120px;
  min-height: calc(100vh - 80px);
  p {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 0px;
    font-size: 16px;
    color: ${props => props.theme.primaryDark};
  }
  li {
    font-size: 16px;
    margin: 4px;
  }
  h3 {
    margin: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .react-tabs__tab--selected {
    color: ${props => props.theme.primary};
    background: white;
    border-color: ${props => props.theme.primary};
    border-radius: 5px 5px 0 0;
    border-top: 2px solid;
    font-weight: bold;
  }
  .react-tabs__tab {
    margin: 0px;
    :hover {
      color: ${props => props.theme.primary};
    }
  }
  .react-tabs__tab-list {
    border-bottom: 1px solid ${props => props.theme.primary};
    margin: 0 0 10px;
    padding: 0;
  }
  .highlight {
    font-weight: bold;
    color: ${props => props.theme.primary};
  }
  .grayed {
    font-weight: light;
    color: #999966;
  }
`;

const ContactUs = styled.div`
  font-weight: 600;
  font-size: 16px;
  padding: 12px;
  width: 100%;
  border-radius: 4px;
  color: ${props => props.theme.positiveColor};
  background: hsla(150, 50%, 40%, 0.1);
`;

const TrainingImage = styled.img`
  height: auto;
  min-width: 30vw;
  max-width: 50vw;
  margin-bottom: 24px;
  max-height: 100vh;
  margin-right: 12px;
`;

const TrainingHeader = styled.div`
  align-items: stretch;
  display: inline;
  display: flex;
  flex-direction: row;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const StyledLI = styled.li`
  font-size: 14px;
  color: ${props => props.theme.primaryDark};
  opacity: 0.8;
`;

const Disclaimer = styled.div`
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 12px;
  border: 2px solid ${props => props.theme.primary};
  background: hsla(354, 70%, 54%, 0.1);
  color: ${props => props.theme.primary};
`;

const ScheduledTime = styled.div`
  color: ${props => props.theme.positiveColor};
  border-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  font-weight: bold;
  font-size: 20px;
  padding: 8px;
  text-transform: uppercase;
  background: hsla(150, 50%, 40%, 0.1);
  @media (max-width: 450px) {
    border-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const EventDetail = styled.div`
  padding: 8px;
  border-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: ${props => props.theme.primaryDark};
  opacity: 0.9;
  font-size: 18px;
  font-weight: 500;
  background: rgba(0, 0, 0, 0.1);
  @media (max-width: 450px) {
    border-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const EventContainer = styled.div`
  display: flex;
  margin: 8px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const UpcomingClassContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  min-width: 50vw;
  align-items: start;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const ClassTime = styled.div`
  color: black;
  border-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  padding: 8px;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.1);
  @media (max-width: 450px) {
    border-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const ClassDetails = styled.div`
  padding: 8px;
  color: grey;
  font-size: 18px;
  font-weight: 500;
  background: white;
  flex-grow: 1;
`;

const ClassStatus = styled.div`
  padding: 8px;
  border-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: ${props =>
    props.available ? props.theme.positiveColor : props.theme.negativeColor};
  opacity: 0.9;
  font-size: 18px;
  font-weight: bold;
  background: ${props =>
    props.available ? "hsla(150, 50%, 40%,0.1)" : "rgba(255,0,0,0.1)"};
  @media (max-width: 450px) {
    border-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
