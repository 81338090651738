import styled from "styled-components";

export default styled.p`
  max-width: 80ch;
  /* text-align: justify; */
  margin: 25px auto;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5;
  text-align: ${props => props.align || "start"};
  word-spacing: 3px;
`;
