import React from "react";
import styled, { css } from "styled-components";

const InputGroup = styled.div`
  position: relative;
  input,
  textarea {
    background: none;
    color: ${props => (props.dark ? "#000" : "#fff")};
    padding: 10px 10px 10px 5px;
    display: block;
    border: none;
    border-bottom: 1px solid ${props => (props.dark ? "#cacaca" : "#fff")};
    width: 100%;
  }
  input:focus,
  textarea:focus {
    outline: none;
  }
  input:focus ~ label,
  textarea:focus ~ label {
    top: -14px;
    font-size: 14px;
    color: ${props =>
      props.dark ? props.theme.primaryDark : props.theme.secondary};
  }

  ${props =>
    props.value !== "" &&
    css`
      input:valid ~ label,
      textarea:valid ~ label {
        top: -14px;
        font-size: 14px;
        color: ${props =>
          props.dark ? props.theme.primaryDark : props.theme.secondary};
      }
      input:not(:valid) ~ label {
        top: -14px;
        font-size: 14px;
        color: red;
      }
      input:not(:valid) ~ .bar:before {
        width: 100%;
        background: red;
      }
    `}

  input:focus ~ .bar:before,
  textarea:focus ~ .bar:before {
    width: 100%;
  }

  label {
    color: ${props => (props.dark ? "#8a8a8a" : "#fff")};
    font-size: 20px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 300ms ease all;
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;
  }
  .bar:before {
    content: "";
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: ${props =>
      props.dark ? props.theme.primaryDark : props.theme.secondary};
    transition: 300ms ease all;
    left: 0%;
  }
`;
export default function Input({
  type,
  label,
  style,
  dark,
  required,
  value = "",
  onChange,
  disabled,
  error,
  minlength,
  maxlength,
  pattern
}) {
  return (
    <InputGroup style={{ ...style }} dark={dark} value={value}>
      <input
        pattern={pattern}
        minlength={minlength}
        maxlength={maxlength}
        type={type}
        required={required}
        error={error}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="bar"></span>
      <label>{label}</label>
    </InputGroup>
  );
}

export function TextArea({
  label,
  style,
  dark,
  required,
  rows,
  value = "",
  disabled,
  onChange,
}) {
  return (
    <InputGroup style={{ ...style }} dark={dark}>
      <textarea
        rows={rows}
        disabled={disabled}
        required={required}
        value={value}
        type="text"
        onChange={onChange}
      />
      <span className="bar"></span>
      <label>{label}</label>
    </InputGroup>
  );
}
