import React from "react";
import PropTypes from "prop-types";

import Header from "../sections/header";
import Footer from "../sections/footer";
import "./layout.css";

const Layout = ({ children, location }) => {
  return (
    <>
      <Header location={location} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
