import styled, { css } from "styled-components";

export default styled.h1`
  text-align: center;
  font-size: 40px;
  margin: 0;
  margin-bottom: 40px;
  position: relative;
  ${props =>
    props.noShadow
      ? ""
      : css`
          &::after {
            /* content: "tedt"; */
            content: "${props => props.text}";
            position: absolute;
            bottom: -5px;
            left: 0;
            right: 0;
            font-size: 3em;
            opacity: 0.1;
          }
        `};
  @media (max-width: 992px) {
    &::after {
      content: "";
    }
  }
`;
