export default {
  title: "",
  aboutUs: "",
  aboutUsImageUrl: "",
  ourMission: "",
  services: [],
  gallery: [],
  trainings: [],
};

export const defaultService = {
  description: "",
  name: "",
  images: [],
  imageUrl: "",
  formattedContent: "",
  innerHtml: "",
  preBookingInfo: "",
  bookingPolicy: "",
  beforeAppointment: "",
  afterAppointment: "",
  contactUs: "",
  customHtml: "",
  faqs: "",
};

export const defaultAlbum = {
  description: "",
  name: "",
  images: [],
};

export const defaultTraining = {
  name: "",
  description: "",
  images: [],
  definition: "",
  courseContent: "",
  topicsCovered: "",
  classSchedule: "",
  prerequisites: "",
  howToSignUp: "",
  disclaimer: "",
  upcomingClasses: "",
  registrationLink: "",
};
