import Layout from "./reusables/layout.jsx";
import About from "./sections/about.jsx";
import Hero from "./sections/hero.jsx";
import Services from "./sections/service/services.jsx";
import Gallery from "./sections/gallery.jsx";
import Contact from "./sections/contact.jsx";
import { useEffect, useState } from "react";
import defaultInfo from "../../helpers/defaultInfo.js";
import { getWebsiteInfo, websiteCache } from "../../helpers/firebase.js";
import Trainings from "./sections/training/trainings.jsx";
import OurMission from "./sections/ourMission.jsx";
import { CenterScreen, Col } from "../styles/styled.js";
import logo from "./images/logo.png";
import styled from "styled-components";
import Reviews from "./sections/reviews.jsx";
import Map from "./sections/map.jsx";
import { Switch, Route } from "react-router-dom";
import { ServiceDetail } from "./sections/service/serviceDetail.jsx";
import { TrainingDetail } from "./sections/training/trainingDetail.jsx";
import { HiDotsHorizontal } from "react-icons/hi";

export default function UserPage() {
  const [websiteInfo, setWebsiteInfo] = useState(defaultInfo);
  const [loading, setLoading] = useState(true);
  const [refetchTimeout, setRefetchTimeout] = useState(1);

  function registerRefetch() {
    setTimeout(() => {
      fetchSiteInfo();
    }, refetchTimeout * 1000);
    setRefetchTimeout(refetchTimeout * 3);
  }

  function fetchSiteInfo() {
    getWebsiteInfo()
      .then(info => {
        setLoading(false);
        setWebsiteInfo({ ...websiteInfo, ...info });
      })
      .catch(e => {
        // setLoading(false);
        registerRefetch();
        console.error(e);
      });
  }

  useEffect(() => {
    fetchSiteInfo();
  }, []);

  if (loading) {
    return (
      <CenterScreen>
        <Col>
          <CenterLogo src={logo}></CenterLogo>
          <HiDotsHorizontal></HiDotsHorizontal>
        </Col>
      </CenterScreen>
    );
  }

  return (
    <>
      <Layout location={window.location}>
        <Switch>
          <Route path="/services/:serviceName">
            <ServiceDetail websiteCache={websiteCache}></ServiceDetail>
          </Route>
          <Route path="/trainings/:trainingName">
            <TrainingDetail websiteCache={websiteCache}></TrainingDetail>
          </Route>
          <Route path="*">
            <>
              <Hero title={websiteInfo.title}/>
              <OurMission ourMission={websiteInfo.ourMission}></OurMission>
              <Services services={websiteInfo.services} />
              <About
                aboutUs={websiteInfo.aboutUs}
                aboutUsImageUrl={websiteInfo.aboutUsImageUrl}
                ourMission={websiteInfo.ourMission}
              />
              <Gallery gallery={websiteInfo.gallery} />
              <Trainings trainings={websiteInfo.trainings} />
              <Reviews></Reviews>
              <Contact instagramEmbedUrl={websiteInfo.instagramEmbedUrl} />
              <Map></Map>
            </>
          </Route>
        </Switch>
      </Layout>
    </>
  );
}

const CenterLogo = styled.img`
  width: 50vw;
  height: auto;
  max-width: 350px;
`;
