import React from "react";
import { FaFacebook, FaGoogle, FaYelp, FaArrowCircleRight } from "react-icons/fa";
import styled from "styled-components";
import Title from "../reusables/title";

export default function Reviews() {
  return (
    <ReviewsSection>
      <div className="container">
        <Title style={{fontSize: '30px', fontWeight: 'normal' }}>What our customers are saying</Title>
        <ReviewsGrid>
          <div style={{border: "1px solid #4285F4"}} className="reviews_grid_item">
            <ReviewPlatform background="#4081EC">
              <FaGoogle style={{color: "#4285F4"}} ></FaGoogle>
            </ReviewPlatform>

            <div className="reviews_grid_title">Zoyah</div>

            <div className="reviews_grid_text">
            Yesterday I got lip tinting done as well as Microblading. 
            When I tell you it it was worth the money that would be an understatement￼.
            Riza is based in Maryland and ...
            </div>
             <div style={{marginTop: '5px'}}>
              <a target="_blank" href='https://www.google.com/search?gs_ssp=eJzj4tVP1zc0TDM0zbBIKks3YLRSNaiwMDMxSDVNM00xt0gzN00ytTKoSDJJtrRMsjBLtjRKNTA1M_USKsqsSlQoLqnMSS1WSEpNLC2pBAD-0RYF&q=riza+styles+beauty&oq=rizsatyles+&aqs=chrome.1.69i57j46i13i175i199.6373j0j7&sourceid=chrome&ie=UTF-8#lrd=0x8640e5f5d78f75b5:0xb4c99b86c92e0565,1,,,'
               style={{backgroundColor:'#4285F4', padding: '5px', color: 'white', letterSpacing: '1px', marginTop: '5px', width: 'fit-content', borderRadius: '5px', textDecoration: 'none'}}>
                review us
                <FaArrowCircleRight style={{color: 'white', verticalAlign: 'middle', margin: '5px'}} ></FaArrowCircleRight>
                </a>
            </div>
          </div>
          <div style={{border: "1px solid #c41200"}} className="reviews_grid_item">
            <ReviewPlatform background="#E81718">
              <FaYelp style={{color: "#c41200"}} ></FaYelp>
            </ReviewPlatform>

            <div className="reviews_grid_title">Julie O</div>

            <div className="reviews_grid_text">
            I have been doing my research since 2018 for Microblading but haven't found someone to whom i can trust!
            Come a crossed with RIZA by INSTA and immediately made ...
            </div>
            <div style={{marginTop: '5px'}}>
              <a target="_blank" href='https://www.yelp.com/biz/artissee-beauty-salon-rockville?hrid=MbzaVm1XS4gikB3MXIuXKA&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)' 
              style={{backgroundColor:'#c41200', padding: '5px', color: 'white', letterSpacing: '1px', marginTop: '5px', width: 'fit-content', borderRadius: '5px', textDecoration: 'none'}}>
                review us
                <FaArrowCircleRight style={{color: 'white', verticalAlign: 'middle', margin: '5px'}} ></FaArrowCircleRight>
                </a>
            </div>
          </div>
          <div style={{border: "1px solid #0F9D58"}} className="reviews_grid_item">
            <ReviewPlatform background="#4081EC">
              <FaGoogle style={{color: "#0F9D58"}}></FaGoogle>
            </ReviewPlatform>

            <div className="reviews_grid_title">Jetzabel Pumarejo </div>

            <div className="reviews_grid_text">
            Riza was so amazing and explained everything from beginning to end.
            I was worried about not liking the shape and being stuck with it but she is so ...
            </div>
            <div style={{marginTop: '5px'}}>
              <a target="_blank" href='https://www.google.com/search?gs_ssp=eJzj4tVP1zc0TDM0zbBIKks3YLRSNaiwMDMxSDVNM00xt0gzN00ytTKoSDJJtrRMsjBLtjRKNTA1M_USKsqsSlQoLqnMSS1WSEpNLC2pBAD-0RYF&q=riza+styles+beauty&oq=rizsatyles+&aqs=chrome.1.69i57j46i13i175i199.6373j0j7&sourceid=chrome&ie=UTF-8#lrd=0x8640e5f5d78f75b5:0xb4c99b86c92e0565,1,,,'
               style={{backgroundColor:'#0F9D58', padding: '5px', color: 'white', letterSpacing: '1px', marginTop: '5px', width: 'fit-content', borderRadius: '5px', textDecoration: 'none'}}>
                review us
                <FaArrowCircleRight style={{color: 'white', verticalAlign: 'middle', margin: '5px'}} ></FaArrowCircleRight>
                </a>
            </div>
          </div>
          <div style={{border: "1px solid #4267B2"}} className="reviews_grid_item">
            <ReviewPlatform background="#1773EA">
              <FaFacebook style={{color: "#4267B2"}}></FaFacebook>
            </ReviewPlatform>

            <div className="reviews_grid_title">Devinie R. Lye</div>

            <div className="reviews_grid_text">
              I found Riza's Salon almost by accident and i'm so glad because
              she is AMAZING! I had my lashes done and a Keratin treatment on my
              hair and I am loving the results!...
            </div>
            <div style={{marginTop: '5px'}}>
              <a target="_blank" href='https://www.facebook.com/rizastylesbeauty/reviews/?ref=page_internal' style={{backgroundColor:'#4267B2', padding: '5px', color: 'white', letterSpacing: '1px', marginTop: '5px', width: 'fit-content', borderRadius: '5px', textDecoration: 'none'}}>
                review us
                <FaArrowCircleRight style={{color: 'white', verticalAlign: 'middle', margin: '5px'}} ></FaArrowCircleRight>
                </a>
            </div>
          </div>
        </ReviewsGrid>
      </div>
    </ReviewsSection>
  );
}

const ReviewsGrid = styled.div`
  display: grid;
  margin-top: 2vw;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-items: center;
  .reviews_grid_item {
    border: 1px solid #9e7c9e4a;
    padding: 16px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 40px;
    width: 250px;
    .reviews_grid_title {
      font-weight: bold;
      font-size: 18px;
      margin: 8px;
    }
    .reviews_grid_text {
      margin: 0;
      font-size: 14px;
      font-weight: light;
    }
    .review_icon {
      margin: auto;
      padding: 4px;
    }
  }
`;

const ReviewPlatform = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  height: 75px;
  width: 75px;
  border-radius: 5%;
`;

const ReviewsSection = styled.section.attrs({
  id: "reviews",
})`
  /* background: red; */
  padding: 5vw 0;
  padding-top: calc(50px + 6vw);
  color: ${props => props.theme.primaryText};
`;
