import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";

// import logo_dark from "../images/logo_dark.png";
import logo from "../images/logo.png";
import BurgerIcon from "../reusables/burger";

function Header({ siteTitle, location }) {
  const scrolled = useScrolled();
  const small = useMedia("(max-width: 992px)");
  const [open, setOpen] = useState(false);
  const home = location && location.pathname === "/";

  return (
    <Toolbar home={home} scrolled={scrolled} collapsed={small} open={open}>
      <div className="container">
        <div className="logo_holder">
          <Link to="/">
            <picture>
              <source srcSet={logo} type="image/png" className="logo" />
              <img src={logo} className="logo" alt="Riza Styles Logo" />
            </picture>
          </Link>
        </div>
        {small ? (
          <BurgerIcon open={open} onChange={() => setOpen(!open)} />
        ) : null}
        <Menu onClick={() => setOpen(false)} collapsed={small} open={open}>
          <Scrollspy
            items={[
              "hero",
              "ourMission",
              "services",
              "about",
              "gallery",
              "trainings",
              "reviews",
              "contact",
            ]}
            currentClassName="active"
            offset={-200}
          >
            <a href="/" className="menu_item">
              <span>Home</span>
            </a>
            <a href="/#ourMission" className="menu_item">
              <span>Our Mission</span>
            </a>
            <a href="/#services" className="menu_item">
              <span>Services</span>
            </a>
            <a href="/#about" className="menu_item">
              <span>About Us</span>
            </a>
            <a href="/#gallery" className="menu_item">
              <span>Gallery</span>
            </a>
            <a href="/#trainings" className="menu_item">
              <span>Trainings</span>
            </a>
            <a href="/#reviews" className="menu_item">
              <span>Reviews</span>
            </a>
            <a href="/#contact" className="menu_item">
              <span>Contact</span>
            </a>
          </Scrollspy>
        </Menu>
      </div>
    </Toolbar>
  );
}

const Menu = styled.nav.attrs({ className: "menu" })`
  ${props =>
    props.collapsed &&
    css`
      transition: max-height 0.2s linear;
      max-height: ${props => (props.open ? "auto" : 0)};
      overflow: hidden;
      position: absolute;
      top: 120px;
      left: 0;
      width: 100%;
      background: #fff;
      .menu_item {
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
      }
    `}
`;

const Toolbar = styled.header`
  width: 100%;
  z-index: 100;
  top: 0;
  position: fixed;
  ul {
    margin: 0;
  }
  .container {
    height: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s;
    .menu {
      align-self: center;
      .menu_item {
        color: white;
        text-decoration: none;
        padding: 1.041vw;
        font-size: 14px;
        font-weight: 600;
        transition: all 0.3s;
      }
      .menu_item:hover {
      }
      ,
      .menu_item.active {
        color: white;
      }
      .menu_item.active span {
        border-bottom: 2px solid;
        padding-bottom: 3px;
      }
    }
  }
  ${props => {
    if (!props.scrolled && props.home) return defaultStyles;
    return scrolledStyles;
  }}
  @media (max-width: 992px) {
    height: auto;
    background: #17192b;
    box-shadow: 0 2px 4px black;
    .container {
      .logo_holder {
        border: 0;
        padding: 0;
        box-shadow: none;
        padding-top: 5px;
        img.logo {
          width: 165px;
          height: auto;
        }
      }
      .menu .menu_item {
        color: #17192b;
      }
      .menu a.menu_item:hover,
      a.menu_item.active {
        color: ${props => props.theme.primary} !important;
      }
    }
  }
`;

const scrolledStyles = css`
  background: #17192b;
  box-shadow: 0 2px 4px black;
  .container {
    .logo_holder {
      padding-top: 5px;
      img.logo {
        width: 165px;
        height: auto;
      }
    }
    .menu .menu_item {
      color: white;
    }
    .menu a.menu_item:hover,
    a.menu_item.active {
      color: ${props => props.theme.primary} !important;
    }
  }
`;

const defaultStyles = css`
  background: transparent;
  .logo_holder {
    padding-top: 5px;
    display: inline-block;
    img.logo {
      width: 165px;
      height: auto;
      margin: 0;
    }
  }
`;

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;

function useScrolled() {
  const [y, setY] = useState(null);

  useEffect(() => {
    setY(window.pageYOffset > 0 ? true : false);
    let updateScrollPosition = () => {
      if (window.pageYOffset > 0) {
        setY(true);
      }
      if (window.pageYOffset === 0) {
        setY(false);
      }
    };
    window.addEventListener("scroll", updateScrollPosition);
    return () => window.removeEventListener("scroll", updateScrollPosition);
  }, []);

  return y;
}

function useMedia(query) {
  let [matches, setMatches] = useState(null);

  useEffect(() => {
    setMatches(window.matchMedia(query).matches);
  }, []);

  useEffect(() => {
    let media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    let listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [query, matches]);
  return matches;
}
