import React from "react";
import styled from "styled-components";
import MapImage from "../images/map.png";

export default function Map() {
  return <MapStyled src={MapImage} alt="Riza Styles Map"></MapStyled>;
}
const MapStyled = styled.img`
  max-width: 100vw;
  min-height: 350px;
  max-height: 350px;
  width: 100%;
  object-fit: cover;
`;
