import firebase from "firebase";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const app = firebase.initializeApp(firebaseConfig);

export function createNewUser() {
  return firebase
    .auth()
    .createUserWithEmailAndPassword("iamsashank@gmail.com", "iamsashank321");
}

export async function loginWithEmailPassword(email, password) {
  return firebase.auth().signInWithEmailAndPassword(email, password);
}

export async function logout() {
  await firebase.auth().signOut();
}

export async function resetPassword() {
  return firebase
    .auth()
    .sendPasswordResetEmail(firebase.auth().currentUser.email);
}

export async function updateAboutUs(aboutUs) {
  return firebase
    .database()
    .ref()
    .child("websiteInfo")
    .child("aboutUs")
    .set(aboutUs);
}

export let websiteCache;

export async function getWebsiteInfo() {
  if (websiteCache) {
    return websiteCache;
  }
  const snap = await firebase.database().ref().child("websiteInfo").get();
  websiteCache = snap.val();
  return websiteCache;
}

export async function setWebsiteInfo(websiteInfo) {
  await firebase.database().ref().child("websiteInfo").set(websiteInfo);
  websiteCache = websiteInfo;
}

export async function uploadFile(file, path) {
  if (file) {
    let ref = firebase.storage().ref().child(path);
    let uploadTaskSnap = await ref.put(file);
    let downloadUrl = await uploadTaskSnap.ref.getDownloadURL();
    return downloadUrl;
  }
}

export function getCurrentUser() {
  return firebase.auth().currentUser;
}
