import React from "react";
import styled from "styled-components";
import hero from "../images/hero.jpg";
import Final15 from "../images/Final15.jpg"
import Stack from "../reusables/stack";
import curve from "../images/curve.svg";
import { Button } from "../reusables/buttons";

export default function Hero({title}) {
  return (
    <HeroSection style={{display: 'flex', flexDirection: 'column-reverse'}}>
      <div className="container">
        <div className="hero-container">
          <p className="headline">Welcome to Rizastyles Beauty</p>
          <h2 style={{color: '#dc3848'}}>Permanent Makeup</h2>
          <Stack gap={15} style={{ flexWrap: "wrap" }}>
            <Button as="a" href="https://linktr.ee/rizastylesbeauty"  target="_blank" className="accent_button">
              BOOK US NOW
            </Button>
          </Stack>
        </div>
      </div>
    </HeroSection>
  );
}

const HeroSection = styled.section.attrs({
  id: "hero",
})`
  height: 100vh;
  position: relative;
  padding-top: 120px;
  background: linear-gradient(#1c1621e8,#c3223214), url(${Final15});
  background-size: cover;
  @media (max-width: 768px) {
    background-size: contain;
    background-repeat: no-repeat;
  }
  @media (max-width: 576px) {
    height: 85vh;
  }
  background-position-y: 20%;
  .hero-container {
    padding-top: 5vw;
    .outline_button,
    .accent_button {
      margin: 5px 5px 5px 0px;
      z-index: 1;
    }
    .headline {
      color: white;
      font-weight: 600;
      font-size: 60px;
      line-height: 1.1em;
      letter-spacing: 1px;
      word-spacing: 8px;
      --min-font: 36;
      --max-font: 72;
      font-size: calc(
        (var(--min-font) * 1px) + (var(--max-font) - var(--min-font)) *
          ((100vw - 420px) / (1200 - 420))
      );
      max-width: 16ch;
    }
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 100px;
    // background-image: url(${curve});
  }
`;
