import { useEffect, useState } from "react";
import styled from "styled-components";
import defaultInfo from "../../helpers/defaultInfo";
import {
  getCurrentUser,
  getWebsiteInfo,
  setWebsiteInfo as setWebsiteInfoFirebase,
  uploadFile,
  logout,
} from "../../helpers/firebase";
import { AbsoluteChild, Col, Row, SizedBox } from "../styles/styled";
import { OutlineButton } from "../User/reusables/buttons";
import Input, { TextArea } from "../User/reusables/input";
import { ManageServices } from "./services";
import { ManageTrainings } from "./trainings";
import { Switch, Route, Link } from "react-router-dom";
import {
  CheckStyled,
  DeleteStyled,
  IconContainer,
  ImageContainer,
  StyledImage,
  StyledLabel,
} from "./styled";
import { ManageGallery } from "./gallery";

export default function Home() {
  const [websiteInfo, setWebsiteInfo] = useState(defaultInfo);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setFile] = useState(null);
  const [selectedImageSrc, setImageSrc] = useState();

  function fileSelected(event) {
    if (event.target.files.length !== 0) {
      var file = event.target.files[0];
      setFile(event.target.files[0]);
      var reader = new FileReader();
      reader.onload = function (e) {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  useEffect(() => {
    getWebsiteInfo()
      .then(info => {
        setLoading(false);
        setWebsiteInfo({ ...websiteInfo, ...info });
      })
      .catch(e => {
        setLoading(false);
        console.error(e);
      });
  }, []);

  return (
    <div>
      <Banner>
        <Row justify="space-between">
          <div>{getCurrentUser()?.email}</div>
          <OutlineButton padding="4px 8px" onClick={logout}>
            Log Out
          </OutlineButton>
        </Row>
      </Banner>
      <Switch>
        <Route path="/admin/services">
          <ManageServices></ManageServices>
        </Route>
        <Route path="/admin/trainings">
          <ManageTrainings></ManageTrainings>
        </Route>
        <Route path="/admin/gallery">
          <ManageGallery></ManageGallery>
        </Route>
        <Route path="*">
          <SizedBox padding="20px">
            <Row>
              <StyledLink to="/admin/services">Services</StyledLink>
              <StyledLink to="/admin/trainings">Trainings</StyledLink>
              <StyledLink to="/admin/gallery">Gallery</StyledLink>
            </Row>
            <SizedBox padding="16px 0px">
              <Input
                required
                dark
                rows={3}
                label="Title"
                value={websiteInfo.title}
                onChange={e =>
                  setWebsiteInfo({
                    ...websiteInfo,
                    title: e.target.value,
                  })
                }
              />
            </SizedBox>
            <SizedBox padding="16px 0px">
              <TextArea
                required
                dark
                rows={6}
                label="About Us"
                value={websiteInfo.aboutUs}
                onChange={e =>
                  setWebsiteInfo({
                    ...websiteInfo,
                    aboutUs: e.target.value,
                  })
                }
              />
            </SizedBox>
            <StyledLabel>About Us Image </StyledLabel>
            {(selectedImageSrc || websiteInfo.aboutUsImageUrl) && (
              <Col>
                <ImageContainer>
                  <StyledImage
                    src={
                      selectedImageSrc
                        ? selectedImageSrc
                        : websiteInfo.aboutUsImageUrl
                    }
                  />
                  <AbsoluteChild top="0px" right="0px">
                    <IconContainer
                      onClick={() => {
                        if (selectedImageSrc) {
                          setImageSrc(null);
                          setFile(null);
                        } else {
                          setWebsiteInfo({
                            ...websiteInfo,
                            aboutUsImageUrl: null,
                          });
                        }
                      }}
                    >
                      <DeleteStyled></DeleteStyled>
                    </IconContainer>
                  </AbsoluteChild>
                </ImageContainer>
              </Col>
            )}
            <SizedBox>
              <input
                type="file"
                name="aboutImageUrl"
                accept="image/"
                id="file_picker"
                onChange={fileSelected}
              />
            </SizedBox>

            <SizedBox padding="16px 0px">
              <TextArea
                required
                dark
                rows={1}
                label="Our Mission"
                value={websiteInfo.ourMission}
                onChange={e =>
                  setWebsiteInfo({
                    ...websiteInfo,
                    ourMission: e.target.value,
                  })
                }
              />
            </SizedBox>
            <SizedBox padding="16px 0px">
              <TextArea
                required
                dark
                rows={6}
                label="Instagram Embed Url"
                value={websiteInfo.instagramEmbedUrl}
                onChange={e =>
                  setWebsiteInfo({
                    ...websiteInfo,
                    instagramEmbedUrl: e.target.value,
                  })
                }
              />
            </SizedBox>

            <Row justify="flex-end">
              {loading ? (
                <div>loading ...</div>
              ) : (
                <CheckStyled
                  onClick={async () => {
                    setLoading(true);
                    if (selectedImage) {
                      websiteInfo.aboutUsImageUrl = await uploadFile(
                        selectedImage,
                        `/aboutUs/${selectedImage.name}`
                      );
                    }
                    await setWebsiteInfoFirebase(websiteInfo);
                    setLoading(false);
                  }}
                ></CheckStyled>
              )}
            </Row>
          </SizedBox>
        </Route>
      </Switch>
    </div>
  );
}

const Banner = styled(SizedBox)`
  padding: 12px 24px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
`;

const StyledLink = styled(Link)`
  padding: 4px;
  margin: 4px;
  font-weight: bold;
  color: ${props => props.theme.primaryDark};
`;
