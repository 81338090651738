import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { getWebsiteInfo } from "../../../../helpers/firebase";
import { CenterScreen, Col, Row, Subtitle } from "../../../styles/styled";
import logo from "../../images/logo.png";
import { Button } from "../../reusables/buttons";
import Title from "../../reusables/title";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

export function ServiceDetail({ websiteCache }) {
  const history = useHistory();
  const [websiteInfo, setWebsiteInfo] = useState(websiteCache);
  const [loading, setLoading] = useState(false);
  const [serviceDetail, setService] = useState();
  const [serviceName, setName] = useState();

  useEffect(() => {
    const pathnameSplit = history.location.pathname.split("/");
    setName(pathnameSplit[pathnameSplit.length - 1]);
  }, [history.location.pathname]);

  useEffect(() => {
    if (!websiteCache) {
      setLoading(true);
      getWebsiteInfo().then(info => {
        setWebsiteInfo(info);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (serviceName && websiteInfo) {
      console.log(serviceName, websiteInfo);
      const service = websiteInfo.services.find(
        service => service.name === serviceName
      );

      if (service) {
        setService(service);
      } else {
        goBack();
      }
    }
  }, [websiteInfo, serviceName]);

  function goBack() {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.replace("/");
    }
  }

  function renderContentTitle(formattedContent) {
    if (formattedContent) {
      const heading = formattedContent.split("->")[0];
      let title = "";
      let subtitle = "";
      if (heading) {
        const headingSplit = heading.split("=>");

        title = headingSplit[0];
        if (headingSplit.length > 1) {
          subtitle = headingSplit[1];
        }
      }

      return (
        <Col padding="12px 0px">
          <h3 dangerouslySetInnerHTML={{ __html: title }}></h3>
          <p dangerouslySetInnerHTML={{ __html: subtitle }}></p>
        </Col>
      );
    }
  }

  function renderBookingPanelContent(content) {
    return (
      <ul>
        {content?.split("->").map((point, index) => {
          // first point is the description
          if (index == 0) {
            return <div></div>;
          }
          return (
            <StyledLI dangerouslySetInnerHTML={{ __html: point }}></StyledLI>
          );
        })}
      </ul>
    );
  }

  function renderCandidate(content) {
    console.log(content)
    return (
      <>
        {renderContentTitle(content)}
        <ul>
          {content?.split("->").map((point, index) => {
            // first point is the description
            if (index == 0) {
              return <span></span>;
            }
            return (
              <StyledLI dangerouslySetInnerHTML={{ __html: point }}></StyledLI>
            );
          })}
        </ul>
      </>
    );
  }
  if (loading)
    return (
      <CenterScreen>
        <picture>
          <source srcSet={logo} type="image/png" className="logo" />
          <img src={logo} className="logo" alt="Riza Styles Logo" />
        </picture>
      </CenterScreen>
    );
  if (!serviceDetail) return <div>loading</div>;
  return (
    <DetailPage>
      <Col className="container" align="start">
        <Title>{serviceName}</Title>
        <ServiceHeader>
          <p
            dangerouslySetInnerHTML={{ __html: serviceDetail?.description }}
          ></p>
          <ServiceImage src={serviceDetail.images? serviceDetail.images[0] : serviceDetail.imageUrl}></ServiceImage>
        </ServiceHeader>
        {renderCandidate(serviceDetail.formattedContent)}

        {serviceDetail.customHtml && (
          <div
            dangerouslySetInnerHTML={{ __html: serviceDetail.customHtml }}
          ></div>
        )}

        <br></br>

        {serviceDetail.bookingWarning && (
          <BookingWarning
            dangerouslySetInnerHTML={{ __html: serviceDetail.bookingWarning }}
          ></BookingWarning>
        )}
        <Tabs>
          <TabList>
            {serviceDetail.preBookingInfo && <Tab>Pre-booking Info</Tab>}
            {serviceDetail.bookingPolicy && <Tab>Booking Policy</Tab>}
            {serviceDetail.beforeAppointment && <Tab>Before Appointment</Tab>}
            {serviceDetail.afterAppointment && <Tab>After Appointment</Tab>}
          </TabList>
          {serviceDetail.preBookingInfo && (
            <TabPanel>
              {renderBookingPanelContent(serviceDetail.preBookingInfo)}
            </TabPanel>
          )}
          {serviceDetail.bookingPolicy && (
            <TabPanel>
              {renderBookingPanelContent(serviceDetail.bookingPolicy)}
            </TabPanel>
          )}{" "}
          {serviceDetail.beforeAppointment && (
            <TabPanel>
              {renderBookingPanelContent(serviceDetail.beforeAppointment)}
            </TabPanel>
          )}{" "}
          {serviceDetail.afterAppointment && (
            <TabPanel>
              {renderBookingPanelContent(serviceDetail.afterAppointment)}
            </TabPanel>
          )}
        </Tabs>
        <br />
        <ContactUs>{serviceDetail.contactUs}</ContactUs>
        {serviceDetail.faqs && (
          <FAQS>
            <h3> Frequently Asked Questions</h3>
            {serviceDetail.faqs?.split("=>").map((point, index) => {
              if (point.trim()) {
                const pointSplit = point.split("->");
                let question = "",
                  answer = "";

                if (pointSplit.length > 1) {
                  answer = pointSplit[1];
                }
                question = pointSplit[0];
                return (
                  <>
                    <Question
                      dangerouslySetInnerHTML={{ __html: question }}
                    ></Question>
                    <Answer
                      dangerouslySetInnerHTML={{ __html: answer }}
                    ></Answer>
                  </>
                );
              }
              return <div></div>;
              // first point is the description
            })}
          </FAQS>
        )}
      </Col>
    </DetailPage>
  );
}

const DetailPage = styled.div`
  padding-top: 120px;
  min-height: calc(100vh - 80px);
  p {
    font-size: 16px;
    color: ${props => props.theme.primaryDark};
  }
  li {
    margin: 4px;
    font-size: 16px;
  }
  .react-tabs__tab--selected {
    color: ${props => props.theme.primary};
    background: white;
    border-color: ${props => props.theme.primary};
    border-radius: 5px 5px 0 0;
    border-top: 2px solid;
    font-weight: bold;
  }
  .react-tabs__tab {
    margin: 0px;
    :hover {
      color: ${props => props.theme.primary};
    }
  }
  .react-tabs__tab-list {
    border-bottom: 1px solid ${props => props.theme.primary};
    margin: 0 0 10px;
    padding: 0;
  }
  .highlight {
    font-weight: bold;
    color: ${props => props.theme.primary};
  }
  .grayed {
    font-weight: light;
    color: #999966;
  }
`;

const ServiceImage = styled.img`
  height: auto;
  min-width: 30vw;
  max-width: 50vw;
  margin-left: 24px;
  max-height: 100vh;
`;

const ServiceHeader = styled.div`
  align-items: stretch;
  display: inline;
  display: flex;
  flex-direction: row;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const StyledLI = styled.li`
  font-size: 14px;
  color: ${props => props.theme.primaryDark};
  opacity: 0.8;
`;

const BookingWarning = styled.div`
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 12px;
  border: 2px solid ${props => props.theme.primary};
  background: hsla(354, 70%, 54%, 0.1);
  color: ${props => props.theme.primary};
`;

const ContactUs = styled.div`
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  padding: 12px;
  width: 100%;
  border-radius: 4px;

  background: hsla(354, 70%, 54%, 0.1);
  color: ${props => props.theme.primary};
`;

const FAQS = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Question = styled.div`
  margin-top: 24px;
  margin-bottom: 0px;
  font-size: 24px;
  color: ${props => props.theme.primaryDark};
`;
const Answer = styled.div`
  font-size: 14px;
  margin-bottom: 24px;
`;
