import React from "react";
import styled from "styled-components";

import logoWhite from "../images/logo.png";
import Stack from "../reusables/stack";
import { Button } from "../reusables/buttons";
import { ReactComponent as FacebookIcon } from "../images/icons/facebook.component.svg";
import { ReactComponent as InstagramIcon } from "../images/icons/instagram.component.svg";

export default function Footer() {
  return (
    <FooterContainer>
      <div className="container">
        <Stack direction="column" gap={20}>
          <div className="footer-start">
            <a href="/">
              <img src={logoWhite} className="logo" alt="Artissee Logo" />
            </a>
            <div className="contact_details">
              <span className="find_us">Find Us At</span>
              <span className="email">contact@rizastyles.com</span>
              <span className="phone"> +1 (240) 474-8833</span>
              <span className="address">
                1327-C Rockville Pike, Rockville, MD 20852
              </span>
              <span class="email" style={{fontSize: '16px'}}>
                Mon - Wed: 11:00am - 6:00pm
              </span>
              <span class="email" style={{fontSize: '16px'}}>Thu - Sat: 10:00am - 7:00pm</span>
              <span class="email" style={{fontSize: '16px'}}>Sun: 12:00pm - 6:00pm</span>
            </div>
          </div>
          <div className="separator"></div>
          <nav className="links">
            <a href="/#ourMission">Mission</a>
            <a href="/#service">Services</a>
            <a href="/#about">About Us</a>
            <a href="/#gallery">Gallery</a>
            <a href="/#trainings">Trainings</a>
            <a href="/#contact">Contact</a>
          </nav>
          <Button as="a" target="_blank" href="https://linktr.ee/rizastylesbeauty" className="accent_button">
              BOOK US NOW
            </Button>
          <div className="footer-end">
            <Stack
              style={{ justifyContent: "space-between", flexWrap: "wrap" }}
            >
              <span className="copyright">
                © {new Date().getFullYear()}, Rizastyles Beauty Permanent Makeup
              </span>
              <div className="socials">
                <a target="_blank" href="https://www.facebook.com/rizastylesbeauty/">
                  <FacebookIcon style={{ height: 30, width: 30 }} />
                </a>
                <a target="_blank" href="https://www.instagram.com/rizastylesbeauty/">
                  <InstagramIcon />
                </a>
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  padding: 24px 0;
  padding-bottom: 0;
  background: #17192b;

  .footer-start {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    span {
      display: block;
    }
    .find_us {
      margin-bottom: 10px;
    }
    .email,
    .phone {
      color: white;
    }
  }
  .container {
    color: hsl(0, 100%, 80%);
  }
  img {
    width: 300px;
    margin: 0 10px 10px 0px;
    /* height: 100%; */
  }
  .separator {
    border-top: 1px solid hsl(0, 100%, 80%);
  }
  nav {
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      a {
        margin: 5px 0;
      }
    }
    a {
      text-transform: uppercase;
      color: inherit;
      margin-right: 20px;
      text-decoration: none;
      font-size: 0.8em;
      &:hover {
        text-decoration: underline;
        color: white;
      }
    }
  }
  .footer-end {
    font-size: 0.7em;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    span {
      @media (max-width: 767px) {
        order: 2;
      }
    }
    .socials {
      order: 1;
      a {
        text-decoration: none;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        svg {
          fill: ${props => props.theme.secondary};
        }
      }
    }
  }
`;
