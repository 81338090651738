import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./Components/App";
import Theme from "./helpers/theme";

ReactDOM.render(
  <React.StrictMode>
    <Theme>
      <App />
    </Theme>
  </React.StrictMode>,
  document.getElementById("root")
);
