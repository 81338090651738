import { app } from "../helpers/firebase";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import AdminPage from "./Admin";
import UserPage from "./User";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/admin">
          <AdminPage />
        </Route>
        <Route path="*">
          <UserPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
