import firebase from "firebase";
import { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Login from "./login";
import AdminHome from "./home";
import styled from "styled-components";

export default function AdminPage() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    if (!currentUser) {
      history.replace("/admin/login");
    } else {
      history.push("/admin");
    }
  }, [currentUser]);

  // handle current user change
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (loading) setLoading(false);
      setCurrentUser(user);
    });
  }, []);

  if (loading) {
    return (
      <Center>
        <div>loading ...</div>
      </Center>
    );
  }

  return (
    <Switch>
      <Route path="/admin/login">
        <Login></Login>
      </Route>
      <Route path="*">
        <AdminHome></AdminHome>
      </Route>
    </Switch>
  );
}

const Center = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;
