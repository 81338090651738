import React, { useState } from "react";
import styled from "styled-components";

import Title from "../reusables/title";
import Text from "../reusables/text";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  AbsoluteChild,
  Caption,
  Col,
  Row,
  SizedBox,
  Subtitle,
} from "../../styles/styled";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import ImgsViewer from "react-images-viewer";

export default function Gallery({ gallery }) {
  const [selectedAlbum, setAlbum] = useState();
  function showAlbumModal(album) {
    setAlbum(album);
  }

  function closeModal() {
    setAlbum();
  }

  return (
    <GallerySection>
      <div className="container">
        <Title style={{fontWeight: 'normal', fontSize: '30px'}}>Browse our gallery </Title>
        <Text></Text>
        <GalleryGrid>
          {gallery.map(album => (
            <SizedBox key={album.name} justify="center" display="flex">
              <SingleSlider key={album.name} album={album}></SingleSlider>
              <AlbumInfo
                bottom="0px"
                left="0px"
                right="0px"
                onClick={() => {
                  showAlbumModal(album);
                }}
              >
                <Subtitle color="white"> {album.name}</Subtitle>
                <Caption color="white"> {album.images.length} photos</Caption>
              </AlbumInfo>
            </SizedBox>
          ))}
        </GalleryGrid>
      </div>
      <Modal open={selectedAlbum}>
        {selectedAlbum && (
          <GalleryDetail album={selectedAlbum} onClose={closeModal}>
            onClose={closeModal}
          </GalleryDetail>
        )}
      </Modal>
    </GallerySection>
  );
}
function HiddenArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "red" }}
      onClick={onClick}
    />
  );
}

function SingleSlider({ album }) {
  const sliderRef = React.createRef();
  return (
    <SliderContainer>
      <StyledSlider {...settings} ref={sliderRef}>
        {album.images.map(image => {
          return (
            <ImageContainer key={image} right="0px">
              <img src={image} alt="" />
            </ImageContainer>
          );
        })}
      </StyledSlider>
      <ArrowContainer
        right="0px"
        type="button"
        className="slick-next arrow"
        onClick={() => {
          sliderRef.current.slickNext();
        }}
      >
        <FaChevronRight></FaChevronRight>
      </ArrowContainer>
      <ArrowContainer
        left="0px"
        type="button"
        className="slick-prev arrow"
        onClick={() => {
          sliderRef.current.slickPrev();
        }}
      >
        <FaChevronLeft></FaChevronLeft>
      </ArrowContainer>
    </SliderContainer>
  );
}

function GalleryDetail({ album, onClose }) {
  const [currentImage, setImage] = useState(0);

  function gotoPrevious() {
    setImage((currentImage - 1) % album.images.length);
  }

  function gotoNext() {
    setImage((currentImage + 1) % album.images.length);
  }
  return (
    <ImgsViewer
      imgs={album.images.map(src => {
        return { src };
      })}
      currImg={currentImage}
      isOpen={true}
      onClickPrev={gotoPrevious}
      onClickNext={gotoNext}
      onClose={onClose}
    />
  );
}

const GallerySection = styled.section.attrs({
  id: "gallery",
})`
  padding: 5vw 0;
  padding-top: calc(50px + 6vw);
  .row {
    margin-top: 50px;
    text-align: center;
  }
  .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      object-fit: cover;
      width: 100%;
      height: auto;
      max-height: 330px;
      border-radius: 8px;
    }
    a {
      color: ${props => props.theme.primaryText};
      margin-top: 18px;
      font-weight: bold;
    }
  }
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: max-content;
  grid-gap: 30px;
  justify-items: center;
  margin-top: 36px;
`;
const StyledSlider = styled(Slider)`
  max-width: 100%;
`;

const settings = {
  nextArrow: <HiddenArrow />,
  prevArrow: <HiddenArrow />,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ArrowContainer = styled.button`
  border: none;
  position: absolute;
  top: ${props => props.top || null};
  bottom: ${props => props.bottom || null};
  right: ${props => props.right || null};
  left: ${props => props.left || null};
  z-index: 99;
  opacity: 1;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const SliderContainer = styled.span`
  width: 300px;
  height: 300px;
  display: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  .arrow {
    display: none;
  }
  :hover {
    .arrow {
      display: flex;
    }
  }
`;

const ImageContainer = styled.div`
  text-align: center;
  outline: none;
  position: relative;
  img {
    object-fit: cover;
    margin: 0 auto;
    height: 300px;
    width: 300px;
    cursor: pointer;
    @media (max-width: 992px) {
      filter: none;
    }
  }
`;

const AlbumInfo = styled(AbsoluteChild)`
  background: rgba(3, 3, 3, 0.5);
  color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 4px;
  cursor: pointer;
`;

const Modal = styled.div`
  display: ${props => (props.open ? "flex" : "none")};
  position: fixed;
  z-index: 101;
  top: 0px;
  bottom: 0px;
  max-height: 100vh;
  max-width: 100vw;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
`;
