import React from "react";
import styled from "styled-components";
import Text from "../reusables/text";
import Title from "../reusables/title";

export default function OurMission({ ourMission }) {
  return (
    <OurMissionSection>
      <div className="container">
        <Title style={{textAlign: "left", backgroundColor: "#e8bdbf", width: "fit-content", padding: "inherit", marginBottom: "-50px", fontWeight: 'normal', fontSize: '30px' }}>Our Mission</Title>
        <Row>
          <Text style={{ lineHeight: "2", borderRight: "4px solid #d09a99", boxShadow: "1px 1px 3px silver", padding: "50px 5px", maxWidth: "100%" }} align="center">{ourMission || ""}</Text>
        </Row>
      </div>
    </OurMissionSection>
  );
}

const Row = styled.div`
  height: fit-content;
  display: flex;
  align-items: ${props => props.align || "start"};
`;

const OurMissionSection = styled.section.attrs({
  id: "ourMission",
})`
  padding: 5vw 0;
  padding-top: calc(50px + 6vw);
  color: ${props => props.theme.primaryText};
`;
