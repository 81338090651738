import React, { useState } from "react";
import styled, { css } from "styled-components/macro";
import Title from "../reusables/title";
import Stack from "../reusables/stack";
import Input, { TextArea } from "../reusables/input";
import { PrimaryButton } from "../reusables/buttons";
import { Row } from "../../styles/styled";

const formDefault = {
  name: "",
  email: "",
  phone: "",
  message: "",
};
export default function Contact({ instagramEmbedUrl }) {
  const [form, setForm] = useState(formDefault);
  const [formState, setFormState] = useState("default");

  function handleSubmit(e) {
    e.preventDefault();
    setFormState("loading");
    fetch(
      "https://us-central1-artissee-beauty.cloudfunctions.net/sendMessage",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      }
    )
      .then(res => res.json())
      .then(res => {
        setFormState("success");
        setForm({ ...formDefault });
      })
      .catch(e => {
        console.log("ERROR", e);
        setFormState("error");
      })
      .finally(_ => {
        setTimeout(() => {
          setFormState("default");
        }, 10000);
      });
    console.log(e);
  }

  return (
    <ContactSectoin>
      <div className="container">
        <Title style={{fontWeight: 'normal', fontSize: '30px'}}>Let's Connect</Title>
        <DynamicContainer>
          <Contacts>
            <form className="form" onSubmit={handleSubmit}>
              <Stack direction="column" gap={36}>
                <Input
                  dark
                  type="text"
                  label="Full Name"
                  style={{ flexGrow: 1 }}
                  required
                  value={form.name}
                  onChange={e =>
                    setForm({
                      ...form,
                      name: e.target.value,
                    })
                  }
                />
                <Stack gap={36}>
                  <Input
                    dark
                    required
                    type="email"
                    label="Email"
                    style={{ flexGrow: 1 }}
                    value={form.email}
                    onChange={e =>
                      setForm({
                        ...form,
                        email: e.target.value,
                      })
                    }
                  />
                   <Input
                    dark
                    required
                    pattern="[1-9]{1}[0-9]{9}"
                    minlength="10"
                    maxlength="10"
                    type="text"
                    label="Phone Number"
                    style={{ flexGrow: 1 }}
                    value={form.phone}
                    onChange={e =>
                      setForm({
                        ...form,
                        phone: e.target.value,
                      })
                    }
                  />
                </Stack>
                <TextArea
                  required
                  dark
                  rows={4}
                  label="Message"
                  value={form.message}
                  onChange={e =>
                    setForm({
                      ...form,
                      message: e.target.value,
                    })
                  }
                />
                {formState === "success" && (
                  <Message>
                    Thank you, We have recieved your message and will contact
                    you shortly.
                  </Message>
                )}
                {formState === "error" && (
                  <WarningMessage>
                    Sorry your message could not be sent. Please try again in a
                    bit.
                  </WarningMessage>
                )}
                <PrimaryButton
                  disabled={formState === "loading"}
                  style={{ alignSelf: "flex-end" }}
                >
                  {formState === "loading" ? "Loading..." : "Submit"}
                </PrimaryButton>
              </Stack>
            </form>
          </Contacts>
          <SocialMedia>
            <Facebook>
              <iframe
                className="embed-responsive-item"
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Frizastylesbeauty%2F&tabs=timeline&width=350&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=430464650330039"
                width="100%"
                height="100%"
                scrolling="no"
                frameborder="0"
                allowTransparency="true"
                allow="encrypted-media"
              ></iframe>
            </Facebook>
            {instagramEmbedUrl && (
              <Instagram
                dangerouslySetInnerHTML={{
                  __html: instagramEmbedUrl,
                }}
              ></Instagram>
            )}
          </SocialMedia>
        </DynamicContainer>
      </div>
    </ContactSectoin>
  );
}

const Message = styled.span`
  color: ${props => props.theme.primary};
  border: 1px solid ${props => props.theme.primary};
  border-radius: 8px;
  padding: 14px;
  background: ${props => props.theme.primaryExtraLight};
`;

const WarningMessage = styled.span`
  color: ${props => props.theme.warning};
  border: 1px solid ${props => props.theme.warning};
  border-radius: 8px;
  padding: 14px;
  background: ${props => props.theme.warningExtraLight};
`;

const ContactSectoin = styled.section.attrs({ id: "contact" })`
  padding-bottom: 0;
  .form {
    margin: 20px;
    width: 100%;
    max-width: 80ch;
    padding: 24px;
    padding-top: 30px;
    border-radius: 8px;
    position: relative;
    background: white;
  }
`;

const Contacts = styled.div`
  border-radius: 12px;
  display: flex;
  flex-grow: 1;
  min-width: 340px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 7px 6px 42px -4px rgba(0, 0, 0, 0.2);
  margin: 12px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const DynamicContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SocialMedia = styled.div`
  display: inherit;
  align-items: stretch;
  @media (max-width: 1000px) {
    flex-direction:row
    display: flex;
    align-items: stretch;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction:column;
  }
`;

const Facebook = styled.div`
  min-width: 50%;
  padding: 8px;
  margin: 12px;
  border-radius: 4px;
  flex-shrink: 1;
  box-shadow: 7px 6px 42px -4px rgba(0, 0, 0, 0.2);
  @media (max-width: 1000px) {
    width: auto;
    align-items: center;
  }
`;

const Instagram = styled.div`
  min-width: 50%;
  padding: 8px;
  margin: 12px;
  flex-shrink: 1;
  border-radius: 4px;
  @media (max-width: 1000px) {
    width: auto;
    align-items: center;
  }
`;
