import React from "react"

export default function Stack({
  children,
  gap = 5,
  direction = "row",
  inline = false,
  style,
}) {
  let items = React.Children.toArray(children)
  let marginDirection
  switch (direction) {
    case "row":
      marginDirection = "marginRight"
      break
    case "column":
      marginDirection = "marginBottom"
      break
    default:
      break
  }

  return (
    <div
      style={{
        display: inline ? "inline-flex" : "flex",
        flexDirection: direction,
        ...style,
      }}
    >
      {items.map((item, i) => {
        if (i === items.length - 1) {
          return item
        }
        return React.cloneElement(item, {
          style: {
            [marginDirection]: gap,
            ...item.props.style,
          },
        })
      })}
    </div>
  )
}
