import React from "react";
import styled from "styled-components";

import Title from "../../reusables/title";
import Stack from "../../reusables/stack";
import { useHistory } from "react-router-dom";

export default function Services({ services }) {
  return (
    <ServicesSection>
      <div className="container">
        <Title style={{fontWeight: 'normal', fontSize: '30px'}}>What we serve</Title>
        <ServiceGrid>
          {services.map(service => {
            return (
              <ServiceItem
                key={service.name}
                title={service.name}
                background={`url(${service.imageUrl})`}
              >
                {service.description.length > 200
                  ? service.description.substring(0, 200) + " ..."
                  : service.description}
              </ServiceItem>
            );
          })}
        </ServiceGrid>
      </div>
    </ServicesSection>
  );
}

function ServiceItem({ title, children, background }) {
  const history = useHistory();
  return (
    <ServiceItemStyled background={background}>
      <Stack
        direction="column"
        style={{ justifyContent: "space-between", height: "100%" }}
      >
        <Stack direction="column" style={{ alignItems: "center" }}>
          <div className="overlay"></div>
          <span className="service_title">{title}</span>
          <div className="separator"></div>
          <p className="text">{children}</p>
        </Stack>
        <ReadMore
          onClick={() => {
            history.push(`/services/${title}`);
          }}
        >
          Read More
        </ReadMore>
      </Stack>
    </ServiceItemStyled>
  );
}

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 30px;
  justify-items: center;
  margin-top: 36px;
`;

const Container = styled.div`
  color: white;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 0 12px 4px #ccca;
  background: #fff;
  padding: 36px;
  position: relative;
  overflow: hidden;
  text-align: center;
  max-width: 400px;
  &:hover {
    .overlay {
      opacity: 0.7;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: ${props => props.theme.primary};
  }
  .circle {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background: #0082c0;
    align-self: center;
  }
  svg {
    height: 16px !important;
    width: 16px !important;
    color: ${props => props.theme.primaryDark};
  }
  .service_title {
    z-index: 3;
    font-size: 24px;
    font-weight: 900;
    margin: 24px 0;
  }
  .separator {
    z-index: 3;

    width: 100px;
    height: 2px;
    background: linear-gradient(
      to right,
      ${props => props.theme.primaryLight},
      ${props => props.theme.primary}
    );
    margin: 20px auto !important;
  }
  .text {
    z-index: 3;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    max-height: 50%;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: #000;
    opacity: 0.4;
  }
`;

const ServiceItemStyled = styled(Container)`
  height: 400px;
  background: ${props => props.background};
  background-repeat: no-repeat;
  background-size: cover;
`;

const ServicesSection = styled.section.attrs({
  id: "services",
})`
  /* background: red; */
  padding: 5vw 0;
  padding-top: calc(50px + 6vw);
`;

const ReadMore = styled.div`
  z-index: 3;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;
